/* Desktop Nav */
.desktop-navbar-container.rs-navbar.rs-navbar-default {
	position: fixed;
	background-color: #428ac9;
	width: 100%;
	height: 60px !important;
}

.rs-navbar-item-icon {
  margin: 0px !important;
}

.rs-navbar-default .rs-navbar-item:hover {
	background-color: #428ac9 !important;
}

.MuiBadge-anchorOriginTopRightRectangle {
	top: 5px !important;
	right: 130px !important;
	transform: scale(1) translate(50%) !important;
	transform-origin: 100% 0% !important;
	border: 1px solid #fafafa;
}

.MuiSvgIcon-root.rs-navbar-item-icon:active {
	border: white;
	background: #fff;
	border-radius: 50px;
	opacity: 0.4;
}

/* Mobile Nav */
@media only screen and (max-width: 480px) {
	.mobile-navbar-top-container {
		height: 60px;
		background-color: #428ac9;
	}

	.mobile-navbar-bottom-container {
		width: 100%;
		position: fixed;
		bottom: 0;
		padding-top: 10px;
		border-top: 1px solid #dedede;
	}

	/* menu icon */
	.MuiBottomNavigationAction-wrapper > .MuiSvgIcon-root {
		color: #727272;
	}

	.MuiBottomNavigationAction-wrapper > .MuiSvgIcon-root:active {
		color: #428ac9;
	}

	.MuiBottomNavigation-root {
		z-index: 2;
	}

	.MuiBottomNavigation-root .Mui-selected {
		color: #428ac9 !important;
		padding-top: 1px;
	}

	.MuiBottomNavigation-root .Mui-selected > .MuiBottomNavigationAction-wrapper > svg {
		color: #428ac9 !important;
	}
}
