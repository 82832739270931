body {
	overflow: hidden !important;
}

/* Works on Firefox */
* {
	scrollbar-width: thin;
	scrollbar-color: rgb(166, 166, 166) rgb(255, 255, 255);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
	width: 7px;
}

*::-webkit-scrollbar-track {
	background: rgb(255, 255, 255);
}

*::-webkit-scrollbar-thumb {
	background-color: rgb(166, 166, 166);
	border: 2px solid rgb(255, 255, 255);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

@media screen and (max-width: 480px) {
	.chat-list-height {
		height: calc(100dvh - 173px) !important;
	}

	.pinned-messages-list-height {
		height: calc(100dvh - 176px) !important;
	}

	.chat-room-height {
		height: calc(100dvh - 130px) !important;
	}

  .chat-request-height {
    height: calc(100dvh - 208px) !important;
  }

	.logo-container {
		display: none;
	}
}

.vertical-scroll-enabled-group-chat {
	height: calc(100dvh - 315px);
	overflow-y: auto;
}

.chat-list-height {
	height: calc(100dvh - 173px);
}

.vertical-scroll-enabled-chat-page {
	height: calc(100dvh - 274px);
	overflow-y: auto;
}

.landing-page-body {
	width: 100%;
	display: flex;
	position: fixed;
	justify-content: center;
	top: 60px !important;
	font-family: 'DM Sans';
	/* bottom: 56px; */
	/* overflowY: 'auto', // TODO need to find a solution for this to be enabled and disabled based on the screen sizes */
}

.internet-connection-status {
	margin-top: 16px;
	position: relative;
}

.chat-list-photo-holder {
	padding: 3px;
	margin-left: 5px;
}

.user-list-row {
	padding: 10px 0px;
	border-bottom: 1px dotted #bbbbbb;
	margin: 5px 0px;
}

.new-group-user-button {
	padding: 10px 0px;
	border-bottom: 3px solid #bbbbbb;
	margin: 5px 0px;
}

.chat-list-image-holder {
	min-height: 48px;
	max-height: 48px;
	min-width: 48px;
	max-width: 48px;
	border-radius: 0.25rem;
}

.group-chat-list-image-holder {
	box-shadow: 0px 0px 1px 1px rgb(173 173 173 / 75%);
}

.chat-room-height {
	height: calc(100dvh - 195px);
}

.chat-request-height {
	height: calc(100dvh - 274px)
}

.password-requirement-tooltip {
	padding-left: 0px;
	margin-bottom: 0px;
	text-align: left;
}

.password-and-icon {
	font-weight: 500;
	display: inline-block !important;
}

.password-info-icon {
	font-size: 1rem !important;
	margin-left: 0.5rem;
	margin-bottom: 4px;
}

.add-chatlist-one-to-one-title {
	padding: 10px 0px;
}

.add-group-chat {
	width: 100%;
}

.add-group-chat > .rs-form-vertical .rs-form-group .rs-form-control-wrapper {
	width: 100% !important;
}

.add-group-chat > .rs-form-vertical .rs-form-group .rs-form-control-wrapper > input {
	width: 100% !important;
}

.css-w4z10b-MuiStack-root {
	flex-wrap: wrap;
}

.send-message-button {
	position: relative;
	left: -30px;
	top: 11px;
}

.MuiButtonBase-root.MuiFab-root.add-group-chat-button {
	color: #ffffff;
	background-color: #1675e0;
	position: fixed;
	bottom: 70px;
	right: 20px;
}

.search-chat-bar {
	background: #ffffff;
	padding: 10px;
}

.message-list-arrow-back-button {
	position: relative;
	top: 5px;
}

.read-receipt-font-size {
	font-size: 14px !important;
	margin: 0px 4px;
}

.read-receipt-color {
	color: #1675e0;
}

.scroll-to-bottom-new-message-alert {
	position: absolute;
}

.new-message-alert {
	justify-content: center;
	background: #fffefe;
	padding: 10px 20px;
	border-radius: 20px !important;
	border: 1px solid #ededed;
	margin-top: 10px !important;
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	color: #575757;
}

.user-picture {
	border-top-left-radius: 12px;
}

.user-description {
	padding: 12px;
	padding-left: 18px;
	border-bottom: 1px solid #c4c4c4;
	border-top: 1px solid #c4c4c4;
}

.rs-modal-content {
	padding: 0px;
	width: 100%;
	background: #ffffff;
	border-radius: 12px;
}

.user-skill {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 21px;
	color: #979797;
	padding-top: 8px;
}

.rs-btn-ghost.rs-btn {
	color: #428ac9;
	padding-left: 18px;
	padding-right: 18px;
	background-color: rgba(255, 255, 255, 1);
	border: 1px solid #428ac9;
	font-weight: 700;
	font-size: 16px;
	line-height: 21px;
}

.rs-btn-primary {
	background-color: #428ac9;
}

.username {
	padding-top: 2%;
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 21px;
	color: rgba(34, 34, 34, 1);
}

.user-profession {
	padding-top: 2px;
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 21px;
	color: #979797;
}

.chat-popup-button-group {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 500 !important;
	font-size: 16px;
	line-height: 21px;
	text-align: center;
	padding-top: 12px;
	padding-bottom: 12px;
}

.user-mentoring-since {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 21px;
	color: #979797;
}

.mentor-star-icon {
	margin-left: 3px;
	margin-right: 3px;
	display: inline-block;
	color: rgba(66, 138, 201, 1);
	vertical-align: top;
}

.skill-tooltip-counter {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 21px;
	color: rgba(34, 34, 34, 1);
}

.skill-tooltip {
	/* opacity: 0; */
	padding: 8px;
}

.user-profession-container {
	padding-top: 2%;
}

.group-chat-name {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 23px;
	color: #222222;
}

.group-chat-description {
	margin-top: 5px;
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	color: #222222;
}

.programme-type-container {
	padding-top: 10px;
	padding-bottom: 14px;
	padding-left: 20px;
	padding-right: 20px;
	border-top: 1px solid #e5e5ea;
	border-bottom: 1px solid #e5e5ea;
}

.programme-label {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 21px;
	color: #222222;
}

.go-to-programme-link {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	text-align: right;
	color: #428ac9;
	padding-top: 0.9px;
}

.programme-name {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	color: #222222;
	margin-top: 5px;
}

.programme-type-label {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 21px;
	color: #222222;
	margin-top: 20px;
}

.programme-type {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	color: #222222;
	margin-top: 5px;
}

.group-members-container {
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 10px;
}

.group-members-and-count {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 21px;
	color: #222222;
	margin-bottom: 8px;
}

.group-users-name {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 20px;
	color: #222222;
}

.group-admin-status {
	color: #428ac9;
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 20px;
}

.MuiTabs-flexContainer {
	background-color: #ffffff;
}

.pinned-by-me {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 11px;
	line-height: 15px;
	color: #979797;
	text-align: right;
}

.pinned-messages-list-height {
	height: calc(100dvh - 176px);
}

.attachment-icon-button-message-room {
	text-align: center;
	height: 44px;
}

.send-message-icon-message-room {
	color: #ffffff;
}

.chat-request-option {
	min-height: 148px;
	background: rgba(0, 0, 0, 0.65);
	opacity: 0.9;
}

.modal-confirmation-title {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 23px;
	text-align: center;
	color: #222222;
	padding-bottom: 21px;
}

.modal-confirmation-description {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 25px;
	text-align: center;
	color: #222222;
	padding-bottom: 36px;
}

.modal-confirmation-reject {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 21px;
	color: #428ac9;
	width: 45%;
}

.modal-confirmation-accept {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 21px;
	color: #ffffff;
	width: 45%;
}

.modal-confirmation-container {
	padding: 17px 46px 0px 46px;
	border-bottom: 1px solid #eaeaea;
}

.modal-confirmation-button-group {
	text-align: center;
	padding-top: 15px;
	padding-bottom: 18px;
}

.highlighted-chat-room-element {
	background: #cee2ef;
}

.social-login-button-container {
	display: flex;
	justify-content: space-around;
}

.alternate-sign-in-option {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 25px;
}

.linkedin-sign-in-button {
	border: 1px solid #a0aec0;
	background-color: #0a66c2;
	color: white;
}

.facebook-sign-in-button {
	border: 1px solid #a0aec0;
	background-color: #1877f2;
	color: white;
}

.google-sign-in-button {
	border: 1px solid #a0aec0;
	background-color: #ffffff;
	border-radius: 40px;
	height: 48px;
	width: 48px;
}

.account-creation-text {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 25px;
	text-align: center;
	padding-top: 16px;
}

.create-an-account-text {
	color: #1675e0;
}

.sign-in-form {
	border-radius: 9.5px;
	--tw-bg-opacity: 1;
	background: #ffffff;
	background-size: contain;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.12);
	padding: 24px 24px 30px 24px;
}

.sign-in-label-text {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
}

.remember-me-and-forgot-password-container {
	margin-bottom: 16px;
	min-height: 36px;
}

.sign-in-email-and-password-text {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	padding-left: 4px;
}

.log-in-text {
	color: #000000;
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 500;
	line-height: 28px;
	font-size: 22px;
	margin-bottom: 16px;
}

.attachment-name {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #575757;
}

.chat-attachment-border {
	border: 1px solid #e5e5ea;
	border-radius: 5px;
	padding: 4px;
}

.homepage-description {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 28px;
	text-align: center;
	color: #575757;
	padding: 0 5% 24px 5%;
}

.homepage-title {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 25px;
	text-align: center;
	color: #575757;
	padding: 16px 5%;
}

.homepage-container {
	padding: 6% 0 7% 0;
	height: calc(100dvh - 132px);
	overflow-y: auto;
}

.homepage-container-desktop {
	padding: 3% 0 0 0;
}

.rs-drawer-right.rs-drawer-xs {
	width: 80%;
}

.user-name {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 20px;
	color: #222222;
	padding: 40px 24px 4px 24px;
}

.user-name-desktop {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 20px;
	color: #222222;
	padding: 16px 24px 4px 24px;
}

.user-email {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	color: #979797;
	padding: 0 24px 30px 24px;
}

.account-type {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #979797;
	padding: 0 24px 4px 24px;
}

.user-type {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	color: #222222;
	padding: 0 24px 20px 24px;
}

.logout-button {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 500 !important;
	font-size: 16px !important;
	line-height: 20px;
	color: #222222;
	padding: 24px !important;
}

.submit-session-feedback-button,
.chat-button,
.go-to-fl-button {
	min-height: 102px;
	margin: 8px;
	border: 1px solid #428ac9;
	border-radius: 12px;
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	display: table;
	color: #0966b7;
}

.session-feedback {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 23px;
	color: #222222;
}

.session-feedback-desktop {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	color: #222222;
}

.new-session {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 28px;
	color: #222222;
}

.previous-session {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 28px;
	color: #222222;
}

.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.required-session {
	font-family: 'DM Sans';
	font-style: normal;
	text-transform: capitalize;
	font-weight: 400;
	font-size: 16px;
	line-height: 23px;
	color: #222222;
}

.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected.required-session {
	font-family: 'DM Sans';
	font-style: normal;
	text-transform: capitalize;
	font-weight: 700;
	font-size: 16px;
	line-height: 23px;
	color: #428ac9;
}

.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.submitted-session {
	font-family: 'DM Sans';
	font-style: normal;
	text-transform: capitalize;
	font-weight: 400;
	font-size: 16px;
	line-height: 23px;
	text-align: center;
	color: #222222;
}

.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected.submitted-session {
	font-family: 'DM Sans';
	font-style: normal;
	text-transform: capitalize;
	font-weight: 700;
	font-size: 16px;
	line-height: 23px;
	color: #428ac9;
}

.feedback-username {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	color: #000000;
	padding-bottom: 6px;
}

.feedback-description {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	color: #979797;
}

.session-date {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	color: #979797;
}

.session-program-name {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	color: #979797;
}

.MuiTabPanel-root.required-session-tab {
	height: calc(100dvh - 202px);
	overflow-y: auto;
}

.MuiTabPanel-root.required-session-desktop-tab {
	height: calc(100dvh - 206px);
	overflow-y: auto;
}

.feedback-session-room-mobile {
	height: calc(100dvh - 147px);
	overflow-y: auto;
}

.feedback-session-form-desktop {
	height: calc(100dvh - 160px);
	overflow-y: auto;
}

.feedback-session-room-desktop {
	height: calc(100dvh - 225px);
	overflow-y: auto;
}

.MuiTabPanel-root.session-detail-summary {
	height: calc(100dvh - 201px);
	overflow-y: auto;
}

.MuiTabPanel-root.session-detail-summary-desktop {
	height: calc(100dvh - 216px);
	overflow-y: auto;
}

.session-detail {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 23px;
	color: #222222;
}

.session-detail-desktop {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 37px;
	color: #222222;
	padding-left: 22px;
}

.select-programme {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	color: #222222;
	padding-bottom: 10px;
}

.session-with {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	color: #222222;
	padding-bottom: 10px;
}

.session-place {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	color: #222222;
	padding-bottom: 10px;
}

.session-date-input {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	color: #222222;
	padding-bottom: 10px;
}

.rs-picker-toggle-value {
	font-family: 'DM Sans';
	color: #979797 !important;
}

.rs-picker-toggle-placeholder {
	font-family: 'DM Sans';
	color: #979797 !important;
}

.radio-button-session-type {
	font-family: 'DM Sans';
	font-size: 16px;
	color: #979797;
}

.session-details-text-error {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	color: #db6057;
	padding-top: 10px;
}

.session-divider.rs-divider.rs-divider-horizontal {
	margin: 0;
}

.hidden.rs-input {
	display: none;
}

.feedback-session-form-mobile.rs-form-group {
	margin-bottom: 0 !important;
	padding: 8px 0 30px 0;
	border-bottom: 1px solid #dedede;
}

.MuiInputBase-input.MuiOutlinedInput-input {
	font-family: 'DM Sans';
	color: #979797 !important;
}

.error-page-container {
	background-image: url('https://futurelab.global/images/star.svg');
	height: 100dvh;
	background-color: #232323;
	overflow-x: hidden;
	justify-content: center;
}

.planet-mars {
	position: absolute;
	top: 35rem;
	left: 10rem;
}

.error-code,
.error-code-mobile {
	font-family: sans-serif;
	font-weight: 900;
	color: #71b4dc;
	-webkit-text-stroke: 4px black;
}

.error-code {
	font-size: 11rem;
}

.error-code-mobile {
	font-size: 7rem;
}

.planet-earth,
.planet-earth-mobile {
	display: inline;
	margin-bottom: 32px;
}

.planet-earth {
	width: 176px;
}

.planet-earth-mobile {
	width: 98px;
}

@keyframes rotate {
	from {
		transform: rotate(0deg) translate(-150px) rotate(0deg);
	}
	to {
		transform: rotate(360deg) translate(-150px) rotate(-360deg);
	}
}

.flying-astronaut {
	position: absolute;
	top: 250px;
	left: 46%;
	animation: rotate 20s infinite linear;
	overflow: hidden;
}
